import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  categoryQuery$: any;
  categories$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  categories: any[] = [];
  constructor(public api: ApiService) {}

  getCategories() {
    this.categoryQuery$ = this.api
      .getAPI<any>('categories')
      .pipe(map((res) => res.body.results))
      .subscribe((categories) => {
        this.categories = categories;
        this.categories$.next(categories);
      });
  }
}
