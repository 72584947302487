import { Injectable, Output } from '@angular/core';
import { Subject, map } from 'rxjs';
import { Survey } from '../_models/survey';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  // properties
  // ----------
  @Output() survey: any;
  @Output() surveyObservable$: Subject<any> = new Subject<any>();

  surveyQuery$: any;
  constructor(public api: ApiService) {}

  getSurveys() {
    return this.api.getAPI<Survey>('surveys').pipe(map((res) => res.body));
  }

  getSurveyById(id: Number) {
    return this.api
      .getAPI<Survey>(`surveys/${id}`)
      .pipe(map((res) => res.body));
  }
}
