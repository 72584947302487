<div
  *ngIf="this.loadingQuestions$ | async"
  class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8"
>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
</div>
<form [formGroup]="surveyFormTest" (ngSubmit)="onSubmitSurveyForm()">
  <div class="formgrid grid p-fluid px-4 py-5 md:px-6 lg:px-8">
    <ng-container *ngFor="let control of controls; let j = index">
      <!-- todo: implement control categories -->
      <ng-container *ngIf="control.category == categories[tab - 1].label">
        <div class="field col-12">
          <!-- question-label -->
          <label class="p-1 text-xl font-semibold" [for]="control.name"
            ><span class="text-green-800 font-bold">{{
              "0" + (j + 1).toString()
            }}</span>
            {{ control.label }}
            <span *ngIf="control.validators.required" class="text-pink-700"
              >*</span
            ></label
          >

          <!-- question-description text-500?-->
          <small
            *ngIf="control.description"
            class="block my-1"
            style="font-size: 16px"
            >{{ control.description }}</small
          >

          <!-- question type: text -->
          <!-- ------------------- -->

          <input
            pInputText
            *ngIf="control.type === 'text'"
            type="text"
            [id]="control.name"
            [formControlName]="control.name"
            placeholder="{{ control.value }}"
            (blur)="surveyFormTest.controls[control.name].markAsDirty()"
          />

          <!-- question type: textarea -->
          <!-- ----------------------- -->

          <textarea
            pInputTextarea
            *ngIf="control.type === 'textarea'"
            [id]="control.name"
            [formControlName]="control.name"
            name="{{ control.name }}"
            [placeholder]="control.value"
            pInputTextarea
            rows="5"
            (blur)="surveyFormTest.controls[control.name].markAsDirty()"
          ></textarea>

          <!-- <div [hidden]="stateNow == 'initial'">
            <label
              class="p-1"
              [for]="control.name"
              *ngIf="control.type === 'textarea'"
              >{{ j + 1 }}. {{ control.label }}
              <span *ngIf="control.validators.required" class="text-pink-700"
                >*</span
              ></label
            >
          </div> -->

          <!-- question type: rating -->
          <!-- --------------------- -->

          <p-selectButton
            *ngIf="control.type === 'rating'"
            [options]="control.valueOptions || []"
            [formControlName]="control.name"
            optionLabel="label"
            optionValue="value"
            (blur)="surveyFormTest.controls[control.name].markAsDirty()"
          >
            <ng-template let-item>
              <div class="">
                <span>{{ item.label }}</span>
              </div>
            </ng-template></p-selectButton
          >

          <!-- question type: select-radio -->
          <!-- --------------------------- -->

          <ng-container *ngIf="control.type === 'select-radio'" class="">
            <div
              *ngFor="let option of control.valueOptions"
              class="field-radiobutton py-1 px-2"
            >
              <p-radioButton
                [formControlName]="control.name"
                [value]="option.value"
              ></p-radioButton>
              <label>{{ option.label }} </label>
            </div>
          </ng-container>

          <!-- question type: select-checkbox -->
          <!-- ---------------------------- -->

          <p-listbox
            *ngIf="control.type === 'select-checkbox'"
            [options]="control.valueOptions || []"
            [formControlName]="control.name"
            [multiple]="true"
            [checkbox]="true"
            [showToggleAll]="false"
            optionLabel="label"
            optionValue="value"
            (blur)="surveyFormTest.controls[control.name].markAsDirty()"
          ></p-listbox>
          <!--extra code allows users to click on other and use it as a textbox-->
          <!--[disabled]="!surveyFormTest.get(control.name)?.value.includes('Other')"-->
          <input
            [hidden]="
              !surveyFormTest.get(control.name)?.value.includes('Other')
            "
            *ngIf="isOtherSelected(control.name)"
            pInputText
            type="text"
            [formControlName]="control.name + '-other'"
            placeholder="Please click other before typing here"
            (blur)="surveyFormTest.controls[control.name].markAsDirty()"
          />

          <!-- question type: rating-order -->
          <!-- --------------------------- -->

          <p-orderList
            *ngIf="control.type === 'rating-order'"
            (onReorder)="onRatingReorder($event, control)"
            [value]="
              surveyFormTest.controls[control.name].value?.length > 0
                ? surveyFormTest.controls[control.name].value
                : control.valueOptions
            "
            [dragdrop]="true"
            [(selection)]="selectedItems"
            [responsive]="true"
            (blur)="surveyFormTest.controls[control.name].markAsDirty()"
            controlsPosition="right"
          >
            <ng-template let-item pTemplate="item">
              <div class="shadow-2 p-4">
                {{ item.label }}
              </div>
            </ng-template>
          </p-orderList>

          <!-- <div
            *ngIf="control.type === 'rating-order'"
            class="field mb-4 col-12"
          >
            <p-table
              [value]="control.valueOptions || []"
              [columns]="cols"
              [reorderableColumns]="true"
              [tableStyle]="{ 'min-width': '50rem' }"
              (onRowReorder)="onRatingReorder($event, control)"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th style="width: 3rem"></th>
                  <th *ngFor="let col of cols" pReorderableColumn>
                    {{ col.header }}
                  </th>
                </tr>
              </ng-template>
              <ng-template
                pTemplate="body"
                let-valueOptions
                let-columns="columns"
                let-index="rowIndex"
              >
                <tr
                  [pReorderableRow]="index"
                  (onReorder)="onOrderChange($event, control)"
                >
                  <td>
                    <span
                      class="pi pi-bars"
                      [pReorderableRowHandle]="index"
                    ></span>
                  </td>
                  <td>{{ index + 1 }}</td>
                  <td>{{ valueOptions.label }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div> -->

          <!-- errors -->
          <small
            *ngIf="
              surveyFormTest.controls[control.name].hasError('required') &&
              surveyFormTest.controls[control.name].touched &&
              surveyFormTest.controls[control.name].invalid
            "
            [id]="control.name"
            class="p-error m-1"
            >The field <span class="font-bold">{{ control.label }}</span> is
            required</small
          >

          <small
            *ngIf="
              surveyFormTest.controls[control.name].hasError('minlength') &&
              surveyFormTest.controls[control.name].touched &&
              surveyFormTest.controls[control.name].invalid
            "
            [id]="control.name"
            class="p-error m-1"
            >The field of
            <span class="font-bold">{{ control.label }}</span> cannot be less
            than {{ control.validators.minLength }} characters</small
          >
          <small
            *ngIf="
              surveyFormTest.controls[control.name].hasError('maxlength') &&
              surveyFormTest.controls[control.name].touched &&
              surveyFormTest.controls[control.name].invalid
            "
            [id]="control.name"
            class="p-error m-1"
            >The field of <span class="font-bold">{{ control.label }}</span> may
            not be exceed {{ control.validators.maxLength }} characters</small
          >
          <small
            *ngIf="
              surveyFormTest.controls[control.name].hasError('pattern') &&
              surveyFormTest.controls[control.name].touched &&
              surveyFormTest.controls[control.name].invalid
            "
            [id]="control.name"
            class="p-error m-1"
            >The field <span class="font-bold">{{ control.label }}</span> can
            only be a number</small
          >
        </div>
      </ng-container>
    </ng-container>
    <div class="field col-6">
      <button
        pButton
        type="button"
        class="p-button p-button-primary p-button-rounded"
        label="Previous"
        (click)="previousCategory()"
        [disabled]="this.tab <= 1"
      ></button>
    </div>
    <div class="field col-6">
      <button
        pButton
        type="button"
        class="p-button p-button-primary p-button-rounded"
        label="Next"
        (click)="nextCategory()"
        [disabled]="!(this.tab < categories.length)"
      ></button>
    </div>
    <div class="field col-12">
      <button
        *ngIf="this.tab === categories.length"
        pButton
        type="submit"
        id="submit"
        class="p-button p-button-primary p-button-rounded p-button-lg font-normal"
        label="Submit"
        [disabled]="!(this.tab === categories.length)"
      >
        <app-success-popup [visible]="successPopupVisible"></app-success-popup>
      </button>
    </div>
  </div>
</form>
<p-progressBar
  [value]="calculateProgress()"
  [style]="{ 'margin-bottom': '20px' }"
></p-progressBar>
