<p-dialog [(visible)]="visible" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '20vw'}" [closable]="false" [showHeader]="false">
  <div *ngIf="visible" class="flex flex-column align-items-center justify-content-center my-4">
    <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3" style="width:64px;height:64px">
      <i class="pi pi-check text-5xl"></i>
    </span>
    <div class="font-medium text-2xl text-900 text-center">Wellness survey Submitted</div>
  </div>
  <p *ngIf="visible" class="line-height-3 p-0 m-0 text-center">
    Thank you for taking the time to complete this survey.
  </p>
  <ng-template pTemplate="footer">
    <div *ngIf="visible" class="border-top-1 surface-border pt-3 flex justify-content-center">
      <button pButton pRipple label="OK" (click)="closePopup()"  routerLink="survey" class="w-6 ml-2"></button>
    </div>
  </ng-template>
</p-dialog>
