import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CategoryService } from '../_services/category.service';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit, OnDestroy {
  @ViewChild('scrollableElement')
  scrollableElement!: ElementRef;
  items: { label: string; routerLink?: string }[] = [];
  progressNumber: number = 0;
  //activeIndex: number = 0;
  activeTab: number = 1;
  questions: any;
  displayedQuestions: any;
  successMessage: boolean = false;
  //this one is the base then loads the endpoints categories
  categories: any[] = [];
  categories$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  //new code
  private categoriesSubscription: Subscription | undefined;

  categoriesLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true,
  );
  constructor(
    private router: Router,
    private categoriesService: CategoryService,
  ) {}

  ngOnInit(): void {
    this.categoriesService.getCategories();
  }

  ngAfterViewInit(): void {
    this.setActiveTab(1);
    this.categoriesSubscription = this.categoriesService.categories$.subscribe(
      (categories: any[]) => {
        //checks here for null
        if (categories) {
          this.categoriesLoading$.next(true);
          this.categories = categories;
          // map categories to categories with index
          const mappedCategories = categories.map(
            (category: any, index: number) => {
              return { label: category.name, index: index + 1 };
            },
          );
          this.categories$.next(mappedCategories);
          this.categoriesLoading$.next(false);
        }
      },
      (error) => {
        console.error('Error fetching categories:', error);
        // Handling errors
      },
    );
  }
  ngOnDestroy(): void {
    if (this.categoriesSubscription) {
      this.categoriesSubscription.unsubscribe();
    }
    //this.categoriesService.categories$.unsubscribe();
  }

  toggleTab(tabNumber: number): void {
    this.activeTab = this.activeTab === tabNumber ? 0 : tabNumber;
  }
  setActiveTab(index: number): void {
    this.activeTab = index;
    const element = this.scrollableElement.nativeElement;
    element.scrollTop = 0;
  }
  FormSubmitted() {
    console.log('Form Successfully Submitted!');
    this.successMessage = true;
  }
  updateProgressNumber(progressNumber: number) {
    this.progressNumber = progressNumber;
    // console.log(console.log('Progress Number:', progressNumber));
  }
}
