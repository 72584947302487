<!-- <p-steps
  class="m-5"
  [model]="items"
  [activeIndex]="activeIndex"
  (activeIndexChange)="onActiveIndexChange($event)"
  [readonly]="false"s
>
</p-steps> -->

<!-- <p class="my-0 mb-4 pb-3 border-bottom-1 surface-border body-font">
    1 of 5 sections
  </p> -->
<p-progressBar
  class="w-full"
  mode="indeterminate"
  *ngIf="categoriesLoading$ | async"
  [style]="{ height: '4px' }"
></p-progressBar>
<div
  class="surface-card md:p-4 shadow-2"
  *ngIf="categories$ | async as loadedCategories"
>
  <div class="flex-column h-full lg:hidden">
    <!-- <p-steps [model]="items" [readonly]="false"></p-steps> -->
  </div>

  <div class="surface-section px-4 py-1 md:py-3 text-center">
    <div class="border-bottom-1 surface-border">
      <span class="block text-3xl font-medium text-900 heading-font">{{
        loadedCategories[activeTab - 1]?.label
      }}</span>
    </div>
  </div>
  <!--
  <p-progressBar
    [value]="progressNumber"
    [style]="{ 'margin-bottom': '20px' }"
  ></p-progressBar>
  -->

  <div class="flex h-full" *ngIf="loadedCategories && activeTab">
    <!-- removes background  overflow-y-scroll-->
    <div class="flex-column h-full hidden lg:flex">
      <!-- Nav bar -->
      <ul class="list-none py-3 px-2 m-0">
        <li class="mb-2" *ngFor="let category of loadedCategories">
          <a
            pRipple
            class="flex align-items-center cursor-pointer p-3 transition-duration-150 transition-colors hover:bg-gray-300 hover:text-green-700 hover:font-bold body-font"
            (click)="toggleTab(category.index)"
            [ngClass]="{
              'bg-green-700 text-white': activeTab === category.index
            }"
          >
            <i
              *ngIf="activeTab === category.index"
              class="pi pi-check-circle pr-2"
            ></i>
            <i
              *ngIf="activeTab !== category.index"
              class="pi pi-circle pr-2"
            ></i>
            <span class="text-left">{{ category.label | uppercase }}</span>
          </a>
        </li>
      </ul>
      <!-- Nav bar ending div -->

      <!-- Nav bar ending  -->
    </div>

    <div class="col-12 lg:col-10">
      <div
        #scrollableElement
        class="overflow-y-scroll overflow-x-hidden"
        style="min-height: 30rem; max-height: calc(100vh - 200px)"
      >
        <!--class="p-3 font-medium text-xl text-900" REMOVED-->
        <div>
          <app-form
            [tab]="activeTab"
            (nextTab)="setActiveTab($event)"
            (progressUpdate)="updateProgressNumber($event)"
            (formSubmitted)="FormSubmitted()"
          >
            <!-- Show the first three questions here -->
          </app-form>
        </div>
      </div>
    </div>
  </div>
</div>
<app-success-popup (visible2)="(successMessage)"></app-success-popup>
