<div
  class="navbar flex align-items-center justify-content-center md:justify-content-start relative"
  style="min-height: 32px; z-index: 1000"
>
  <!--lg:static lg:shadow-2 shadow-0 bg-white-->
  <img
    src="https://rescriptconsulting.com/wp-content/uploads/2023/02/Re-script-Consultinglogo.png"
    alt="Image"
    height="48px"
    class="mr-2 lg:mr-4 mb-2 lg:mb-0"
  />
  <!-- Added mb-2 and lg:mb-0 for spacing adjustment -->
  <div
    class="text-800 font-bold text-2xl heading-font border-bottom-3 border-green-600 align-self-center"
    style="margin-top: 5px;"
  >
    <!-- Increased border thickness to border-bottom-5 but didnt work it just disapears -->
    Wellness Survey
  </div>
</div>
<p></p>
<div class="flex-auto" style="max-height: calc(100vh - 80px)">
  <router-outlet></router-outlet>
</div>
