<div
  id="hero"
  class="flex flex-column pt-4 px-4 lg:px-8 justify-content-center align-items-center"
  style="
    background: linear-gradient(0deg, #53a233, #fff);
    min-height: 90vh;
    background-image: url(&quot;assets/images/bg.png&quot;);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  "
>
  <div class="flex justify-content-center align-items-center">
    <!--md:justify-content-end-->

    <!-- <img
      src="assets/images/landing/hero.png"
      alt="Hero Image"
      class="w-9 md:w-auto"
    /> -->

    <div
      class="w-full md:w-8 px-4 py-8 md:px-6 lg:px-8 border-round fadeindown animation-duration-1000"
      style="
        backdrop-filter: blur(40px);
        background-color: rgba(255, 255, 255, 0.5);
      "
    >
      <h2
        class="text-3xl border-bottom-3 border-green-800"
        style="font-family: &quot;Shippori Mincho B1&quot;; font-weight: 400"
      >
        Rescript Consulting - Wellness Survey
      </h2>

      <div class="overflow-y-scroll my-5" style="max-height: 40vh">
        <p
          class="line-height-3 mt-0 mb-7 px-5 text-md"
          style="
            font-family: &quot;Open Sans&quot;, sans-serif;
            font-weight: 300;
          "
        >
          <b class="text-green-900">Defining Wellness</b> - Keep the following
          in mind when you answer this survey. We will be asking questions
          regarding the 5 dimensions of your wellness in this survey. The best
          research shows that wellness can be segmented into 6 basic dimensions
          of our life. We will measure your general wellness, and arrive at a
          general wellness score, under the following headings: <br /><br />
          1. Interpersonal Relationships <br />
          2. Career/job <br />
          3. Physical body <br />
          4. Living space <br />
          5. The logistics of your life <br />
          6. General baseline wellness<br /><br />
          <b class="text-green-900">What do we mean by wellness?</b><br />
          Wellness for this survey, means the following: <br />
          1. The joy and privilege of experiencing healthy and fulfilling
          relationships in our lives.<br />2. Having a fulfilling and meaningful
          career <br />3. Being able to enjoy your physical body <br />4. Having
          a healthy and friendly living space <br />5. Being able to handle the
          day to day organisation of your life <br /><br />

          <u>Keep in mind to complete this survey as honestly as possible.</u
          ><br />
          <br>
          <b class="text-green-900 text-xl">Why?</b> <br /><br />So that you may
          benefit from the honest results! This survey is designed to assist
          people in determining where exactly they can start in addressing their
          wellness. If you feel that you're actually quite OK, then doing this
          survey can also provide you with some very practical ways to optimise
          and even increase your wellness towards fulfilment! Now doesn't that
          sound great? Be honest, and enjoy the process - then you will benefit
          from this exercise.
        </p>
      </div>
      <button
        pButton
        pRipple
        label="Start"
        class="p-button-success p-button-rounded p-button-lg fadeindown animation-duration-1000 animation-delay-500"
        icon="pi pi-arrow-right"
        iconPos="right"
        routerLink="survey"
      ></button>
    </div>
  </div>
</div>
