import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-success-popup',
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.scss']
})
export class SuccessPopupComponent implements OnInit {
  @Input() visible: boolean = false;
  constructor(
    public dialogService: DialogService
  ) { }

  closePopup() {
    this.visible = false;
  }
  ngOnInit(): void {
  }
}
