// question.service.ts
import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, map } from 'rxjs';
import { ApiService } from './api.service';
import { Question } from '../_models/question';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  @Output() question: any;
  @Output() questionObservable$: Subject<any> = new Subject<any>();

  questionQuery$: any;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    //api 
    public api: ApiService) { }

  getQuestions(): Observable<any> {
    return this.http.get<any>('../assets/questions.json');
  }
  //TESTing dynamic form
  createForm(jsonConfig: any[]): FormGroup {
    const formGroupConfig: { [key: string]: any } = {};

    jsonConfig.forEach(field => {
      const fieldValidators = this.createValidators(field.validators);
      formGroupConfig[field.field] = ['', fieldValidators];
    });

    return this.fb.group(formGroupConfig);
  }
  private createValidators(validatorStrings: string[]): any[] {
    return validatorStrings.map(validatorString => {
      const [validatorName, arg] = validatorString.split('(');

      if (arg) {
        const argValue = parseInt(arg.slice(0, -1), 10);
        return (Validators as any)[validatorName](argValue);
      } else {
        return (Validators as any)[validatorName];
      }
    });
  }

  // endpoints from db
  getQuestion() {
    return this.api.getAPI<Question>('surveys').pipe(map((res) => res.body));
  }

  getQuestionById(id: Number) {
    return this.api
      .getAPI<Question>(`surveys/${id}`)
      .pipe(map((res) => res.body));
  }
}
