import { Injectable, Input, Output } from '@angular/core';
import { Observable, Subject, map } from 'rxjs';
import { Response } from '../_models/response.';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ResponseService {
  // properties
  // ----------
  @Input() response: any;
  @Input() responseObservable$: Subject<any> = new Subject<any>();

  responseQuery$: any;
  constructor(public api: ApiService) { }

  getResponses() {
    return this.api.getAPI<Response>('responses').pipe(map((res) => res.body));
  }
  postResponse(responseData: Response): Observable<Response> {
    return this.api.postAPI<Response>('responses', responseData);
  }
}