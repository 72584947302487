import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  asNativeElements,
} from '@angular/core';
//import { DialogService } from 'primeng/dynamicdialog';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
import { HttpClient } from '@angular/common/http';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ApiService } from '../_services/api.service';
import { Question } from '../_models/question';
import { SurveyService } from '../_services/survey.service';
import { ResponseService } from '../_services/response.service';
import { QuestionService } from '../_services/question.service';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { Survey } from '../_models/survey';
import { CustomFormControl } from '../_models/custom-form-controls';
import { CustomFormValidators } from '../_models/custom-form-validators';
import { CategoryService } from '../_services/category.service';
import { Response } from '../_models/response.';
import { Answer } from '../_models/answer';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  // animations: [
  //   // animation triggers go here
  //   trigger('revealDependentControl', [
  //     state(
  //       'initial',
  //       style({
  //         opacity: 0,
  //         hidden: 'true',
  //       }),
  //     ),
  //     state(
  //       'final',
  //       style({
  //         opacity: 1,
  //         hidden: 'false',
  //       }),
  //     ),
  //     transition('initial => final', animate('1000ms linear')),
  //   ]),
  // ],
})
export class FormComponent implements OnInit {
  constructor(
    private api: ApiService,
    private questionService: QuestionService,
    private fb: FormBuilder,
    public surveyService: SurveyService,
    public responseService: ResponseService,
    private categoriesService: CategoryService,
    private dialogService: DialogService,
  ) { }


  //commented out the defualt questions
  controls: CustomFormControl[] = [];

  categories: any[] = [];

  submitFormLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  @Input() surveys: Survey[] = [];
  @Input() responses: Response[] = [];

  @Output() formSubmitted = new EventEmitter();
  surveys$: Subject<any[]> = new Subject<any[]>();
  surveyQuery$: any;
  public surveyFormTest: FormGroup = this.fb.group({});
  public surveyForm: FormGroup = this.fb.group({});

  loadingQuestions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true,
  );
  loading: boolean = false;

  //Select a row
  // selectedRowIndices: number[] = [];

  selectedItems: any[] = [];
  stateNow: string = 'initial';
  showSuccessPopup: boolean = false;
  ngOnInit() {
    // this.showPopup();
    const surveyObservable = this.surveyService.getSurveyById(1);
    // const categoriesObservable = this.categoriesService.getCategories();
    const categoriesObservable$ = this.categoriesService.categories$;

    combineLatest([surveyObservable, categoriesObservable$]).subscribe(
      ([survey, categories]) => {
        this.loadingQuestions$.next(true);
        // console.log('Surveys:', survey);
        //this.questions = survey.questions;
        // Sort the questions by their IDs in ascending order
        this.questions = survey.questions.sort((a: Question, b: Question) => {
          // Perform null checks for a.id and b.id to avoid potential runtime errors
          if (a.id !== undefined && b.id !== undefined) {
            return a.id - b.id;
          }
          // Return 0 if either a.id or b.id is undefined
          return 0;
        });

        //sort questions to controls
        this.controls = this.mapQuestionsToControls(this.questions);
        //console.log('Controls:', this.controls);
        this.buildSurveyForm(this.controls);
        this.nextTab.emit(this.tab);

        this.categories = categories.map((category: any, index: number) => {
          return { label: category.name, index: index + 1 };
        });
        this.loadingQuestions$.next(false);
        //console.log('Categories:', this.categories);
        this.retrieveAndPostStoredResponses();
      },
      (error) => {
        console.error('Error fetching data:', error);
      },
    );
  }

  // Function to serialize FormGroup object into JSON
  serializeForm(form: FormGroup): string {
    const formValue = form.value;
    //console.log('Form values before se', this.surveyFormTest.value);
    // Convert select-checkbox arrays to string arrays before serialization
    const serializedValue = { ...formValue };
    return JSON.stringify(serializedValue);
  }

  // Function to deserialize JSON string into a FormGroup object
  deserializeForm(serializedForm: string): any {
    const formData = JSON.parse(serializedForm);
    // Convert string arrays back to arrays of the original type
    for (const key in formData) {
    }
    return formData;
  }
  // was my version of the function
  onOrderChange(event: any, controlName: any) {
    //this.surveyFormTest.controls[controlName].patchValue(this.selectedItems);

    // Extract the reordered items
    const reorderedItems = event.items.map((item: any) => item.data);

    // If the reordered items contain arrays, flatten them
    const flattenedItems = reorderedItems.flatMap((item: any) =>
      Array.isArray(item) ? item : [item],
    );

    // Update the form control's value with the reordered items
    this.surveyFormTest.value[controlName].setValue(flattenedItems);
  }
  successPopupVisible: boolean = false;

  onSubmitSurveyForm() {
    if (this.surveyFormTest.valid) {
      this.submitFormLoading$.next(true);
      //console.log('form valid', this.surveyFormTest.valid);
      //console.log('form value', this.surveyFormTest.value);

      const response: Response = {
        date_started: new Date(),
        date_completed: new Date(),
        survey_id: 1,
        answers: [],
      };

      for (const control of this.controls) {
        const controlValue = this.surveyFormTest.get(control.name)?.value;

        // If 'Other' option is selected, replace its value with custom input
        if (this.isOtherSelected(control.name)) {
          const otherControlValue = this.surveyFormTest.get(`${control.name}-other`)?.value;
          // Replace 'Other' with custom input
          if (Array.isArray(controlValue)) {
            controlValue.forEach((val: string, index: number) => {
              if (val === 'Other') {
                controlValue[index] = otherControlValue;
              }
            });
          }
        }

        //control value based on its type
        let answerValue: string;
        if (Array.isArray(controlValue)) {
          if (typeof controlValue[0] === 'object') {
            // Extract the label property from each object in the array
            const options = controlValue.map((option: any) => option.label);
            answerValue = options.join(', '); // Concatenate the labels
          } else {
            answerValue = controlValue.join(', ');
          }
        } else {
          answerValue = controlValue.toString();
        }

        const answer: Answer = {
          response_value: answerValue,
          //response_value: option.value.toString(),
          //response_value: controlValue.toString(),
          question_id: parseInt(control.name),
        };
        response.answers.push(answer);

        //  if (controlValue !== undefined) {
        // const answer: Answer = {
        // response_value: controlValue.toString(),
        /// question_id: parseInt(control.name)
        // };
        //response.answers.push(answer);
      }
      // Send the single response to the server
      this.responseService.postResponse(response).subscribe(
        (res) => {
          this.formSubmitted.emit();
          console.log('Response sent successfully:', res);
          // Clear session storage after successful submission
          sessionStorage.clear();
          this.successPopupVisible = true;
        },
        (error) => {
          console.error('Error sending response:', error);
        },
      );
      console.log('form has been submitted');
      this.submitFormLoading$.next(true);
    } else {
      // Ensures that form controls are marked as touched and dirty to display error messages
      this.surveyFormTest.markAllAsTouched();

      for (const control of Object.keys(this.surveyFormTest.controls)) {
        this.surveyFormTest.get(control)?.markAsDirty();
      }

      // Iterate over form controls to check validity and collect error messages
      const errorMessages: string[] = [];
      for (const controlName of Object.keys(this.surveyFormTest.controls)) {
        const control = this.surveyFormTest.get(controlName);

        // Check if control is invalid and has been touched or modified
        if (
          control?.invalid &&
          (control?.touched || control?.dirty || control?.pristine)
        ) {
          // Get the corresponding control details from the controls array
          const controlDetails = this.controls.find(
            (c) => c.name === controlName,
          );

          // So user can find specific question that is wrong
          let errorMessage = `${controlDetails?.label} is invalid`;

          // Iterate over each validation error and append error message to the list
          for (const errorName of Object.keys(control?.errors || {})) {
            switch (errorName) {
              case 'required':
                errorMessage += ' - Field is required';
                break;
              case 'email':
                errorMessage += ' - Field must be a valid email address';
                break;
              case 'minlength':
                errorMessage += ` - Field must be at least 3 characters`;
                break;
              case 'pattern':
                errorMessage += ` - Field must be number`;
                break;
            }
          }
          // Push the error message
          errorMessages.push(errorMessage);

        }
      }
      // Displaying error
      if (errorMessages.length > 0) {
        const errorMessage = 'Form submission is invalid. Please check the following Questions:\n' +
          errorMessages.join('\n');

        // Show the alert dialog
        const confirmation = confirm(errorMessage);
        if (confirmation) {
          // Navigating the category with invalid questions
          const firstInvalidControl = Object.keys(this.surveyFormTest.controls).find(controlName =>
            this.surveyFormTest.controls[controlName].invalid
          );

          // Find the category index of the first invalid control
          const categoryIndex = this.controls.find(control => control.name === firstInvalidControl)?.category;

          if (categoryIndex) {
            const category = this.categories.find(category => category.label === categoryIndex);
            if (category) {
              // goes to the category with invalid questions
              this.nextTab.emit(category.index);

            }
          }
        }
      }
    }
  }
  //rating order think this was mine
  onRatingReorder($event: any, formControl: any) {
    // get the value of the orderlist by retrieving the value of the html component
    // and patching it to the form control

    if (formControl.type === 'rating-order') {
      this.surveyFormTest.controls[formControl.name].patchValue(
        formControl.valueOptions,
      );
    }
  }


  // Function to check if 'Other' checkbox is selected
  isOtherSelected(controlName: string): boolean {
    const controlOptions = this.controls.find(c => c.name === controlName)?.valueOptions;
    const otherOption = controlOptions?.find(option => option.value === 'Other');
    return otherOption
  }


  storeQuestionsInSession(currentCategory: any) {
    //console.log('Pre serialize');
    //console.log(currentCategory);
    const serializedForm = this.serializeForm(currentCategory);
    //console.log('Post serialize');
    //console.log(serializedForm);
    sessionStorage.setItem(`formAnswers`, serializedForm);
  }

  onCheckboxChange(event: any, controlName: string) {
    // Get the selected values
    const selectedValues = event.value;

    // Update the form control with the selected values
    this.surveyFormTest.get(controlName)?.setValue(selectedValues);

    // Serialize the selected values and store them in session storage
    const serializedValues = JSON.stringify(selectedValues);
    sessionStorage.setItem(controlName, serializedValues);
  }
  @Output() progressUpdate = new EventEmitter();

  calculateProgress(): number {
    // Calculate the number of completed categories
    const completedCategories = this.tab - 1; // Assuming tab index starts from 1

    // Calculate the total number of categories
    const totalCategories = this.categories.length;

    // Calculate the completion progress
    const progress = (completedCategories / totalCategories) * 100;
    this.progressUpdate.emit(progress);
    return progress;
  }
  //navbar per question progress goes over 100% when all questions are answered
  XcalculateProgress(): number {
    // Count the number of answered questions
    const answeredQuestions = Object.keys(this.surveyFormTest.controls)
      .filter(controlName => this.surveyFormTest.controls[controlName].valid);

    // Calculate progress based on the total number of questions and answered questions
    const totalQuestions = this.controls.length;
    const answeredCount = answeredQuestions.length;
    return (answeredCount / totalQuestions) * 100;
  }
  nextCategory() {
    if (this.tab <= this.categories.length + 1) {
      const currentCategory = this.categories.find(
        (category) => category.index === this.tab,
      )?.label;
      //console.log('controls pls', this.surveyFormTest);

      if (currentCategory) {
        this.storeQuestionsInSession(this.surveyFormTest); // Store questions before moving to next category
      }
      this.tab++;
      this.nextTab.emit(this.tab);
      //console.log('tab', this.tab);
    }
  }

  previousCategory() {
    if (this.tab >= 1) {
      const currentCategory = this.categories.find(
        (category) => category.index === this.tab,
      )?.label;
      if (currentCategory) {
        this.storeQuestionsInSession(this.surveyFormTest); // Store questions before moving to next category
      }
      this.tab--;
      this.nextTab.emit(this.tab);
      //console.log('tab', this.tab);
    }
  }

  // Retrieves stored responses for each category and sends them to the server
  retrieveAndPostStoredResponses() {
    const storedResponseString = sessionStorage.getItem(`formAnswers`);
    //console.log('Pre deserialize');
    //console.log(storedResponseString);
    if (storedResponseString) {
      const serializedFormValues = this.deserializeForm(storedResponseString);
      //console.log('Post deserialize');
      //console.log(serializedFormValues);
      this.surveyFormTest.patchValue(serializedFormValues);
      //console.log('Form values after de', this.surveyFormTest.value);
    }
  }

  buildSurveyForm(controls: CustomFormControl[]) {
    for (const control of controls) {
      const validatorsToAdd = [];
      for (const [key, value] of Object.entries(control.validators)) {
        switch (key) {
          case 'min':
            validatorsToAdd.push(Validators.min(value));
            break;
          case 'max':
            validatorsToAdd.push(Validators.max(value));
            break;
          case 'required':
            if (value) {
              validatorsToAdd.push(Validators.required);
            }
            break;
          case 'requiredTrue':
            if (value) {
              validatorsToAdd.push(Validators.requiredTrue);
            }
            break;
          case 'email':
            if (value) {
              validatorsToAdd.push(Validators.email);
            }
            break;
          case 'minLength':
            validatorsToAdd.push(Validators.minLength(value));
            break;
          case 'maxLength':
            validatorsToAdd.push(Validators.maxLength(value));
            break;
          case 'pattern':
            validatorsToAdd.push(Validators.pattern(value));
            break;
          case 'nullValidator':
            if (value) {
              validatorsToAdd.push(Validators.nullValidator);
            }
            break;
          case 'number':
            if (value) {
              validatorsToAdd.push(Validators.pattern(/^-?\d*(\.\d+)?$/));
            }
            break;
          case 'alphabetic':
            if (value) {
              validatorsToAdd.push(Validators.pattern(/^[a-zA-Z\s]*$/));
            }
            break;
          default:
            break;
        }
      }
      this.surveyFormTest.addControl(control.name,
        // fixme: the default value we don't necessarily want to be the value on the control
        this.fb.control('', validatorsToAdd),
      );

      // Adds separate form control for 'Other' input if 'Other' checkbox is present
      if (this.isOtherSelected(control.name)) {
        this.surveyFormTest.addControl(
          control.name + '-other',
          this.fb.control('')
        );
      }
    }
  }

  revealDependentControl(control: CustomFormControl) {
    if (control.dependent) {
      this.stateNow = 'final';
      return this.surveyFormTest.controls[control.dependentControl ?? '']
        ?.dirty;
    }
    return false;
  }

  mapQuestionsToControls(questions: Question[]) {
    return questions.map((question) => this.mapQuestionToControl(question));
  }

  mapQuestionToControl(question: Question) {
    return {
      name: String(question.id),
      label: question.label,
      description: question.question_description,
      value: question.question_text,
      type: question.question_type,
      category: question.category.label,
      // options: question.options,
      required: question.required,
      validators: question.validators.reduce(
        (acc, validator) => {
          acc[validator.validator_type] = validator.validator_value;
          return acc;
        },
        {} as { [key in keyof CustomFormValidators]?: any },
      ),
      valueOptions: question.value_options.map((valueOption) => ({
        label: valueOption.option_text,
        value: valueOption.option_value,
      })),
    };
  }

  //auto gen
  @Input() questions: Question[] = [];
  displayedQuestions: any[] = [];
  // Custom multiSelect not dynamic json
  options = [
    { label: 'Male', value: 'option1' },
    { label: 'Female', value: 'option2' },
    { label: 'Other', value: 'other' }, // Add a custom option
  ];
  selectedOption: any;
  selectedRating: any;
  customText: any;
  @Input() tab: number = -1;
  @Output() nextTab = new EventEmitter();
  // End of Custom multiSelect

  cols: any[] = [
    { field: 'rating', header: 'Rating' },
    { field: 'category', header: 'Category' },
  ];

  //auto gen

  /*
  get firstname() {
    return this.surveyForm.controls['firstname'];
  }
*/
  ratingOptions: any[] = [];

  rateOptions: any[] = [];

  selectedRate: any;

  filteredControls: CustomFormControl[] = [];
}
